import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <p>Hi, this is not the payment page, please request the correct payment url</p>
    <a href="//www.agatajuliamakeup.com">Go back to site</a>
  </Layout>
)

export default IndexPage
